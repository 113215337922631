import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";


export const Profile = {
  getReserveProfile: async (token: string | null, config?: AxiosRequestConfig) => {
    const response = await axiosInstance.get('/auth/getReserveFromUser', {
      ...config,
      headers: {
        ...(config?.headers || {}),
        Authorization: token ? `Bearer ${token}` : '',
      },
    });
    return response.data; 
  },
  getDoneBookings: async (config?: AxiosRequestConfig) => {
    const response = await axiosInstance.get(
      `/auth/get-reserves-from-user-by-month`,
      config
    );
    return response;
  },
  deleteReserveProfile: async (id: number, config?: AxiosRequestConfig) => {
    const response = await axiosInstance.delete(
      `/auth/tours/${id}`,
      config
    );
    return response;
  },
};
