import { useAppDispatch } from "../../../redux/hooks"
import { FunctionComponent, Dispatch, SetStateAction } from "react"
import { useAppSelector } from "../../../redux/hooks"
import { RootState } from "../../../redux/store"
import { Link, useNavigate } from "react-router-dom"
import {
  DrawerContainer,
  CloseContainer,
  Close,
  ListContainer,
  ItemText,
  Container,
  Button,
  DividerBottom,
  Logo,
  ContainerLogos,
  ContainerCuenta,
} from "./styles"
import { Divider } from "@mui/material"
import CloseImage from "../../../assets/images/Menu/cancelButton.svg"
import FundacionLogo from "../../../assets/images/Menu/fundacionLogo.svg"
import ArcorLogo from "../../../assets/images/Header/LogoArcor.svg"
import { Avatar } from "../Avatar"
import {
  setEdit,
  setInitialState,
} from "../../../redux/reservas/editReservaSlice"
import { setInitiState } from "../../../redux/reservas/reservasSlice"

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>
  open: boolean
}

export const MenuMobile: FunctionComponent<Props> = ({
  setOpen,
  open,
}: Props) => {
  const { token } = useAppSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const items = [
    {
      name: "LA EXPERIENCIA",
      src: "/",
    },
    {
      name: "QUIENES SOMOS",
      src: "/quienes-somos",
    },
    {
      name: "RESERVA TU VISITA",
      src: `${token ? "/reserva-tour" : "/auth/register"}`,
      // src: "/comingsoon"
    },
    {
      name: "CONTACTO",
      src: "/contacto",
    },
  ]

  const handleResetState = (url?: string) => {
    dispatch(setEdit())
    dispatch(setInitiState())
    // dispatch(setInitialState());
    if (url) {
      navigate(url)
    }
  }

  return (
    <DrawerContainer
      variant="temporary"
      anchor="left"
      open={open}
      transitionDuration={700}
    >
      <Container>
        <CloseContainer>
          <Close
            alt="Close Button"
            src={CloseImage}
            onClick={() => setOpen(false)}
          />
        </CloseContainer>
        <ListContainer>
          {items.map((element, index) => (
            <ItemText key={index}>
              <Link
                to={element.src}
                style={{ textDecoration: "none", color: "#000" }}
                onClick={() => handleResetState()}
              >
                {element.name}
              </Link>
            </ItemText>
          ))}
          {token ? (
            <ContainerCuenta>
              <Avatar />
              <ItemText onClick={() => handleResetState("/user/profile")}>
                MI CUENTA
              </ItemText>
            </ContainerCuenta>
          ) : (
            <Button
              onClick={() => handleResetState("/auth/register")}
              // onClick={() => handleResetState('/comingsoon')}
            >
              INGRESA
            </Button>
          )}
        </ListContainer>
        <ContainerLogos>
          <img
            alt="Logo Arcor"
            src={ArcorLogo}
            style={{
              marginRight: "17px",
              width: "100.13px",
              height: "69.07px",
            }}
          />
          <Divider
            flexItem
            orientation="vertical"
            sx={{ backgroundColor: "#3366CC", width: "2px" }}
          />
          <Logo alt="Logo Fundacion" src={FundacionLogo} />
        </ContainerLogos>
      </Container>
    </DrawerContainer>
  )
}
