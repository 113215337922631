import { useState, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getTourBookEdit } from "../../../redux/reservas/editReservaSlice"
import { enterTourCalls } from "../../../services/enterTourApiCalls"
import { useNavigate } from "react-router-dom"
import {
  ContainerCard,
  DetailContainer,
  DayContainer,
  DayText,
  TourDataContainer,
  DataTourText,
  TourDetailsContainer,
  DetailText,
  EnterTourButton,
  ContainerEditButton,
  EditButton,
  CopyIconContainer,
  IconCopy,
  IconEdit,
  CancelButton,
} from "./styles"
import EditIcon from "../../../assets/images/profile/EditIcon.svg"
import CopyIcon from "../../../assets/images/profile/CopyIcon.svg"
import moment from "moment"
import "moment/locale/es"
import { RootState } from "../../../redux/store"
import { sweetAlertConfirm, sweetAlertMixin } from "../../../helpers/sweetAlert"
import { Spinner } from "../../Spinner"
import {
  deleteReserveProfile,
  getTourProfile,
} from "../../../redux/profile/profileSlice"

type GuideProps = {
  id: string
  name: string
}

interface PivotProps {
  tour_book_id: number
  tour_path_id: number
}

interface PathProps {
  active: number
  created_at: string
  description: string
  id: number
  name: string
  pivot: PivotProps
  updated_at: string
}

interface ElementProps {
  name: string
  date: string
  participants: number
  grade: string
  tour_code: string
  access_code: number
  guide: GuideProps
  hour: string
  id: number
  paths: PathProps[] | []
  institution: string
}

type Props = {
  nextBooking?: any
  element?: any
}

export const Card = ({ nextBooking, element }: Props) => {
  const { user } = useAppSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [dayFormat, setDayFormat] = useState<any>()
  const [route, setRoute] = useState<any>()
  const [loadingEnterTour, setLoadingEnterTour] = useState(false)

  moment.locale("es")

  const formatDateAndHour = (object: typeof element) => {
    const writeDay = moment(object?.date).format("dddd")
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1)
    const dateDay = moment(object?.date).format("DD/MM")
    const formatHours = object?.hour.substring(0, 5)
    const newHour = `${formatHours} hs`

    setDayFormat({
      day: firstLetterUppercase,
      date: dateDay,
      hours: newHour,
    })
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Codigo de acceso: ${element?.tour_book_code.toString()} Número de tour: ${element?.tour_code.toString()}` ||
        "No se copio"
    )
  }

  const handleEnterTour = async () => {
    setLoadingEnterTour(true)
    const bodyParameters = {
      tour_book_code: element?.tour_book_code,
      tour_code: element?.tour_code,
      name: user.user.name,
    }
    try {
      const response = await enterTourCalls.enterTour(bodyParameters)
      const currentDate = new Date().getTime()
      const tourDate = new Date(
        `${response.data.data.tour_book.date}T${response.data.data.tour_book.hour}`
      ).getTime()
      if (currentDate >= tourDate) {
        setLoadingEnterTour(false)
        window.open(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SUBDOMINIO_URL}/index.html?name=${response.data.data.name}&code=${element?.tour_book_code}&tour_code=${element?.tour_code}&isGuide=${response.data.data.is_guide}`,
          "_blank"
        )
      } else {
        sweetAlertMixin(
          "error",
          "Todavia no comenzó la experiencia, revisa la fecha y hora."
        )
        setLoadingEnterTour(false)
      }
    } catch (err: any) {
      if (err.response.status === 405) {
        sweetAlertMixin(
          "error",
          'La experiencia todavía no comenzó. Si el problema persiste contacta a soporte'
        )
      } else if (err.response.status == 404) {
        sweetAlertMixin(
          "error",
          "No pudimos encontrar la reserva, revisa tus datos ingresados."
        )
      } else {
        sweetAlertMixin(
          "error",
          "Ocurrio un error, intente nuevamente mas tarde."
        )
      }
      setLoadingEnterTour(false)
    }
  }

  const handleEdit = () => {
    dispatch(getTourBookEdit(element?.id))
    navigate("/reserva-tour")
  }

  const handleDeleteReserve = async (id: number) => {
    const response = await sweetAlertConfirm()
    if (response) {
      await dispatch(deleteReserveProfile(id))
      const token = user.access_token || sessionStorage.getItem("token")
      dispatch(getTourProfile(token))
    }
  }

  useEffect(() => {
    if (element) {
      formatDateAndHour(element)
      setRoute({
        firstRoute: element?.paths[0],
        secondRoute: element?.paths[1],
      })
    }
  }, [element])

  return (
    <ContainerCard>
      <DetailContainer>
        <DayContainer nextBooking={nextBooking}>
          <DayText>
            {dayFormat?.day} {dayFormat?.date} - {dayFormat?.hours}{" "}
            {element?.name}
          </DayText>
        </DayContainer>
        <TourDataContainer>
          <CopyIconContainer>
            <IconCopy alt="Copy Icon" src={CopyIcon} onClick={handleCopy} />
          </CopyIconContainer>
          <DataTourText>
            Número de Tour: {element?.tour_code} <br /> Código de acceso:{" "}
            {element?.tour_book_code}
          </DataTourText>
        </TourDataContainer>
        <TourDetailsContainer>
          <DetailText>Capacidad: {element?.participants}/ 30</DetailText>
          <DetailText>Grado: {element?.grade}</DetailText>
          <DetailText>
            Colegio: {element?.institution || "Sin definir"}
          </DetailText>
          <DetailText>
            Recorrido:{" "}
            {route?.secondRoute?.name
              ? `${route?.firstRoute?.name} + ${route?.secondRoute?.name}`
              : `${route?.firstRoute?.name}`}
          </DetailText>
        </TourDetailsContainer>
      </DetailContainer>
      {nextBooking && (
        <EnterTourButton onClick={handleEnterTour} disabled={loadingEnterTour}>
          {loadingEnterTour ? (
            <Spinner width="30px" height="30px" color="#000000" />
          ) : (
            "INGRESAR AL TOUR"
          )}
        </EnterTourButton>
      )}
      <ContainerEditButton nextBooking={nextBooking}>
        <EditButton onClick={handleEdit}>
          <IconEdit alt="Icon Edit" src={EditIcon} />
          EDITAR
        </EditButton>
        <CancelButton onClick={() => handleDeleteReserve(element?.id)}>
          Cancelar Turno
        </CancelButton>
      </ContainerEditButton>
    </ContainerCard>
  )
}
