import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette:{
        primary: {
            main: '#3366CC',
            light: '#00559D',
            dark: '#103F63',
            contrastText: '#FFF'
          },
        secondary: {
            main: '#FEC90A',
            contrastText: '#000'
        },
        info:{
            main: '#103F63',
            contrastText: '#000'
        }
    }
});

