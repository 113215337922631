import "./App.css"
import { useEffect } from "react"
import store from "./redux/store"
import { Provider } from "react-redux"
import { AppRouter } from "./routers/AppRouter"
import { ThemeProvider } from "@mui/material/styles"
import { theme } from "./theme"
import "react-toastify/dist/ReactToastify.css"

// Google Analytics
import ReactGA from "react-ga4"

function App() {
  useEffect(() => {
    if (
      window.location.href === "https://universoarcor.com/" ||
      window.location.href === "https://universoarcor.com.ar/"
    ) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Init",
      })
    }
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </Provider>
  )
}

export default App
