import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import FondoDesktop from "../../assets/images/profile/FondoDesktop.svg";
import FondoMobile from "../../assets/images/profile/FondoMobile.svg";

type Props = {
  isEmpty: boolean;
};

export const Container = styled(Box)<Props>(({ theme, isEmpty }) => ({
  width: "100vw",
  minHeight: "100vh",
  backgroundImage: `url(${FondoMobile})`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "top",
  [theme.breakpoints.up("sm")]: {
    backgroundSize: "cover",
  },
  [theme.breakpoints.up("md")]: {
    backgroundImage: `url(${FondoDesktop})`,
    backgroundPosition: "top",
    backgroundSize: isEmpty ? "cover" : "contain"
  },
}));
