import { useEffect } from 'react';
import { getTourProfile } from '../redux/profile/profileSlice';
const UseFetchTourProfile = (user: any, dispatch:any) => {
  useEffect(() => {
    const fetchTourProfile = async () => {
        const token = user?.access_token || sessionStorage.getItem('token');
          await dispatch(getTourProfile(token));
    };

    fetchTourProfile();

  }, [user, dispatch]);
};

export default UseFetchTourProfile;