import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { Container, Title } from './styles';
import { Card } from '../../Card'

export const NextBookings = (handleDeleteReserve: any) => {

  const { loading, profile } = useAppSelector((state: RootState) => state.profile);

  if (profile.length <= 0) return null;


  return (
    <>
      <Container>
        <Title>Próximo Tour</Title>
        <Card nextBooking={true} element={profile[0]} />
      </Container>
    </>
  )
}