import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  nextBooking?: boolean;
};

export const ContainerCard = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  padding: "20px",
  gap: "30px",
  maxWidth: "240px",
  maxHeight: "501.25px",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
}));

export const DetailContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  width: "100%",
  height: "238px",
}));

export const DayContainer = styled(Box)<Props>(({ nextBooking }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "15px 10px 30px",
  gap: "10px",
  width: "210px",
  height: "69px",
  borderBottom: nextBooking ? "4px solid #3366CC" : "4px solid #E1EBFF",
}));

export const DayText = styled(Typography)(() => ({
  width: "188px",
  height: "35px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "18px",
  color: "#000000",
}));

export const CopyIconContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));

export const IconCopy = styled("img")(() => ({
  width: "18px",
  height: "18px",
  cursor: "pointer",
}));

export const TourDataContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "11.25px 7.5px 22.5px",
  gap: "0px 7.5px 7.5px 7.5px",
  width: "210px",
  height: "75px",
  borderBottom: "4px solid #3366CC",
}));

export const DataTourText = styled(Typography)(() => ({
  width: "100%",
  height: "41px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "15px",
  lineHeight: "20px",
  color: "#000000",
}));

export const TourDetailsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "30px",
  marginBottom: "30px",
  width: "100%",
  height: "66px",
}));

export const DetailText = styled(Typography)(() => ({
  width: "100%",
  height: "51px",
  fontSize: "10.5px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "13px",
}));

export const EnterTourButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15.0636px 30.1272px",
  width: "210px",
  height: "37px",
  background: "#F2C94C",
  borderRadius: "86.3647px",
  marginTop: "30px",
  color: "#000",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "14.25px",
  textAlign: "center",
  transition: "all .3s ease",
  "&:hover": {
    background: "#F8E4A5",
  },
}));

export const ContainerEditButton = styled(Box)<Props>(({ nextBooking }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: nextBooking ? "0px" : "20px",
  marginBottom: nextBooking ? "0px" : "20px",
}));

export const IconEdit = styled("img")(() => ({
  width: "12px",
  height: "12px",
}));

export const EditButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "14px",
  fontSize: "12px",
  lineHeight: "14.25px",
  "&:hover": {
    background: "none",
  },
}));

export const CancelButton = styled(Typography)(() => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "14.25px",
  textDecoration: "underline",
  color: "#000000",
  textTransform: 'uppercase',
  cursor: 'pointer'
}))
