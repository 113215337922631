import { useState } from 'react';
import { Header } from "../components/Header"
import { MenuMobile } from '../components/Header/MenuMobile';
import { Footer } from '../components/Footer';

interface Props {
    children: JSX.Element | JSX.Element[]
}

export const HeaderLayout = ({ children }: Props) => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Header setOpen={setOpen} />
            <MenuMobile setOpen={setOpen} open={open} />
            {children}
            <Footer />
        </>
    )
}