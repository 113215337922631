import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import { Container, Text } from './styles';
import { Card } from '../../Card';


export const ScheduledBookings = (handleDeleteReserve: any) => {
  const { loading, profile } = useAppSelector((state: RootState) => state.profile);

  if (profile.length <= 0) return null;

  return (
    <Container>
      <Text>Agendados</Text>
      {profile?.map((element, i) => (
        i !== 0 &&
        <Card key={i} element={element} />
      ))}
    </Container>
  )
}