import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  isOpen?: boolean;
};

export const ContainerCard = styled(Box)<Props>(({ isOpen }) => ({
  display: isOpen ? "flex" : 'none',
  height: '100%',
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  padding: "20px",
  gap: "30px",
  maxWidth: "240px",
  maxHeight: "501.25px",
  background: "#FFFFFF",
  boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.3)",
  borderRadius: "14px",
}));

export const DetailContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px",
  width: "100%",
  height: "318px",
}));

export const DayContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "11px 7px 22px",
  gap: "10px",
  width: "210px",
  height: "68px",
  borderBottom: "4px solid #E1EBFF",
}));

export const DayText = styled(Typography)(({ theme }) => ({
  width: "250px",
  height: "46px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "27px",
  color: "#000000",
  [theme.breakpoints.up('md')]:{
    fontSize: '14px',
    lineHeight: '17px'
  }
}));

export const CopyIconContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));

export const IconCopy = styled("img")(() => ({
  width: "18px",
  height: "18px",
  cursor: "pointer",
}));

export const TourDataContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "15px 10px 30px",
  gap: "0px 7px 7px 7px",
  width: "210px",
  height: "74px",
  borderBottom: "4px solid #3366CC",
}));

export const DataTourText = styled(Typography)(({ theme }) => ({
  width: "100%",
  height: "54px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#000000",
  [theme.breakpoints.up('md')]:{
    fontSize: '14px',
    lineHeight: '17px'
  }
}));

export const TourDetailsContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginTop: "30px",
  marginBottom: "30px",
  marginLeft: "20px",
  width: "100%",
  height: "66px",
}));

export const DetailText = styled(Typography)(() => ({
  width: "100%",
  height: "51px",
  fontSize: "10.5px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "17px",
}));

export const EnterTourButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "15.0636px 30.1272px",
  width: "280px",
  height: "49.13px",
  background: "#F2C94C",
  borderRadius: "86.3647px",
  marginTop: "30px",
  color: "#000",
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16.0678px",
  lineHeight: "19px",
  textAlign: "center",
  transition: "all .3s ease",
  "&:hover": {
    background: "#F8E4A5",
  },
}));

export const ContainerEditButton = styled(Box)<Props>(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "20px",
  marginBottom: "20px",
}));

export const IconEdit = styled("img")(() => ({
  width: "24px",
  height: "24px",
}));

export const EditButton = styled(Button)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginLeft: "18px",
  gap: "14px",
  "&:hover": {
    background: "none",
  },
}));
