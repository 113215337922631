import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

type Props = {
  isMobile: boolean;
}

export const Container = styled(Box)(({ theme }) => ({
  width: "90vw",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "auto",
  [theme.breakpoints.up('md')]:{
    width: '70vw'
  }
}));

export const TitleContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "70px",
  marginBottom: "30px",
}));

export const Title = styled(Typography)<Props>(({ isMobile }) => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: isMobile ? "18px" : "24px",
  lineHeight: "149.4%",
  color: "#000000",
}));

export const QuantityContainer = styled(Box)<Props>(({ isMobile }) => ({
  display: isMobile ? "none" : "flex",
  gap: "5px",
}));

export const NumberToursText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "149.4%",
  color: "#000000",
}));

export const ToursText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 400,
  fontSize: "24px",
  lineHeight: "149.4%",
  color: "#000000",
}));

export const ButtonBooking = styled(Button)<Props>(({ isMobile }) => ({
  display: isMobile ? "none" : 'block',
  padding: "12px 23px",
  gap: "10px",
  background: "#3366CC",
  borderRadius: "86px",
  color: "#FFFFFF",
}));

export const MonthContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: '90vw',
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "23px 10px",
  gap: "20px",
  margin: '0px auto 0px auto',
  [theme.breakpoints.up('md')]:{
    width: '70vw'
  }
}));

export const MonthsCardContainer = styled(Box)(() => ({
  width: "100%",
  padding: "20px 20px 0px 20px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "39px 75px",
  flexWrap: "wrap",
}));

export const FloatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  marginBottom: "2rem",
  zIndex: theme.zIndex.appBar,
  marginTop: 0,
}));

export const Btn = styled(Button)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "19px",
  textAlign: "center",
  color: "#FFFFFF",
  borderRadius: "86px",
  padding: "15px 30px",
}));

export const EmptyBookings = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "149.4%",
  color: "#000000",
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    fontSize: "32px"
  }
}))