import { configureStore, Action, ThunkAction } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import reservasSlice from "./reservas/reservasSlice";
import statesGradesSlice from "./reservas/statesGradesSlice";
import toursSlice from "./reservas/toursSlice";
import profileSlice from "./profile/profileSlice";
import editReservaSlice from "./reservas/editReservaSlice";
// import { authReducer } from "./reducers/authReducer/authReducer";

const store = configureStore({
  reducer: {
    auth: authSlice,
    reservas: reservasSlice,
    StateGrades: statesGradesSlice,
    tours: toursSlice,
    profile: profileSlice,
    editTour: editReservaSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
