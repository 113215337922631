import { useState, useEffect } from "react"
import {
  ContainerCard,
  DetailContainer,
  DayContainer,
  DayText,
  TourDataContainer,
  DataTourText,
  TourDetailsContainer,
  DetailText,
  CopyIconContainer,
  IconCopy,
} from "./styles"
import CopyIcon from "../../../assets/images/profile/CopyIcon.svg"
import moment from "moment"
import "moment/locale/es"

type Props = {
  element?: any
  isOpen?: boolean
}

export const Card = ({ element, isOpen }: Props) => {
  const [dayFormat, setDayFormat] = useState<any>()

  moment.locale("es")

  const formatDateAndHour = (object: typeof element) => {
    const writeDay = moment(object.date.day).format("dddd")
    const firstLetterUppercase =
      writeDay.charAt(0).toUpperCase() + writeDay.substring(1)
    const dateDay = moment(object.date.day).format("DD/MM")
    const formatHours = object.date.hour.substring(0, 5)
    const newHour = `${formatHours} hs`

    setDayFormat({
      day: firstLetterUppercase,
      date: dateDay,
      hours: newHour,
    })
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `Codigo de acceso: ${element?.code.toString()} Número de tour: ${element?.tour_id.toString()}` ||
        "No se copio"
    )
  }

  useEffect(() => {
    if (element) {
      formatDateAndHour(element)
    }
  }, [element])

  return (
    <ContainerCard isOpen={isOpen}>
      <DetailContainer>
        <DayContainer>
          <DayText>
            {dayFormat?.day} {dayFormat?.date} - {dayFormat?.hours} Experiencia
            Virtual
          </DayText>
        </DayContainer>
        <TourDataContainer>
          <CopyIconContainer>
            <IconCopy alt="Copy Icon" src={CopyIcon} onClick={handleCopy} />
          </CopyIconContainer>
          <DataTourText>
            Número de Tour: {element?.tour_id} <br /> Código de acceso:{" "}
            {element?.code}
          </DataTourText>
        </TourDataContainer>
        <TourDetailsContainer>
          <DetailText>Capacidad: {element?.participants}/ 30</DetailText>
          <DetailText>Grado: {element?.grade_id}</DetailText>
          <DetailText>
            Colegio: {element?.institution || "Sin definir"}
          </DetailText>
          {/* <DetailText>Recorrido: {element?.paths[0].description || 'Sin definir'} + {element?.paths[1].description  || 'Sin Definir'}</DetailText> */}
        </TourDetailsContainer>
      </DetailContainer>
    </ContainerCard>
  )
}
