import { useAppDispatch } from '../../../redux/hooks';
import { logout } from '../../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { Container, FlexContainer, Icon, IconText, ContactoContainer, Text, LinkContacto, LogoutContainer, Logout, DividerTop } from './styles';
import InfoIcon from '../../../assets/images/profile/infoIcon.svg';
import BackIcon from '../../../assets/images/profile/backIcon.svg';

export const Information = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/')
  }

  return (
    <Container>
      <DividerTop orientation='horizontal' textAlign='center' variant='middle' />
      <FlexContainer onClick={() => navigate('/terminos-condiciones')}>
        <Icon
          alt='Information icon'
          src={InfoIcon}
        />
        <IconText>INFORMACIÓN IMPORTANTE</IconText>
      </FlexContainer>
      <FlexContainer onClick={() => navigate('/mis-tours')}>
        <Icon
          alt='Back Icon'
          src={BackIcon}
        />
        <IconText>TOURS REALIZADOS</IconText>
      </FlexContainer>
      <ContactoContainer>
        <Text>¿Tenes dudas?</Text>
        <LinkContacto to='/contacto'>CONTACTATE CON NOSOTROS</LinkContacto>
      </ContactoContainer>
      <LogoutContainer>
        <Logout onClick={handleLogout}>
          CERRAR SESIÓN
        </Logout>
      </LogoutContainer>
    </Container>
  )
}