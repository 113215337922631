import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "50px auto 50px auto",
  width: "80%",
  gap: "95px",
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}));

export const FloatContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  bottom: 0,
  marginBottom: "2rem",
  zIndex: theme.zIndex.appBar,
  marginTop: 0,
}));
