import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = {
  isEmpty: boolean;
  loading: boolean;
};

export const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    justifyContent: "center",
  },
}));

export const BookingsContainer = styled(Box)<Props>(
  ({ theme, isEmpty, loading }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: loading ? "center" : "flex-start",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: isEmpty || loading ? "center" : "space-between",
      alignItems: isEmpty ? "center" : "flex-start",
      padding: "0px 0px 0px 30px",
      gap: "87px",
    },
  })
);

export const NotReserveText = styled(Typography)(({ theme }) => ({
  fontFamily: "Raleway",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "149.4%",
  color: "#000000",
  [theme.breakpoints.up('sm')]:{
    fontSize: '32px'
  }
}));
