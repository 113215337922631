import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { RootState } from "../../redux/store"
import { useNavigate } from "react-router-dom"
import { doneBookings } from "../../redux/profile/profileSlice"
import { useMediaQuery } from "@mui/material"

import {
  Container,
  TitleContainer,
  Title,
  QuantityContainer,
  NumberToursText,
  ToursText,
  ButtonBooking,
  MonthContainer,
  FloatContainer,
  Btn,
  EmptyBookings,
} from "./styles"
import { SwipeContainer } from "./SwipeContainer"
import { Spinner } from "../Spinner"

export const MyTours = () => {
  const navigate = useNavigate()
  const isMobile = useMediaQuery("(max-width:900px)")

  const [counter, setCounter] = useState(0)
  const { doneBookingsShow, loading } = useAppSelector(
    (state: RootState) => state.profile
  )

  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)

  useEffect(() => {
    const token = user.access_token || sessionStorage.getItem("token")
    dispatch(doneBookings(token))
  }, [])

  useEffect(() => {
    if (doneBookingsShow.length) {
      let counterBookings: number = 0
      doneBookingsShow.map((el: any) => {
        counterBookings += el.bookings.length
      })
      setCounter(counterBookings)
    }
  }, [doneBookingsShow])

  return (
    <Container>
      <TitleContainer>
        <Title isMobile={isMobile}>Mis Tours realizados</Title>
        <QuantityContainer isMobile={isMobile}>
          <NumberToursText>{counter}</NumberToursText>
          <ToursText>realizados</ToursText>
        </QuantityContainer>
        <ButtonBooking
          variant="contained"
          isMobile={isMobile}
          onClick={() => navigate("/reserva-tour")}
        >
          RESERVÁ NUEVO TOUR
        </ButtonBooking>
      </TitleContainer>
      {!doneBookingsShow.length && !loading && (
        <EmptyBookings>No tenes tours realizados</EmptyBookings>
      )}
      {doneBookingsShow.length > 0 && !loading && (
        <MonthContainer>
          {doneBookingsShow.map((el: any, index: number) => (
            <>
              <SwipeContainer element={el} key={index} />
            </>
          ))}
        </MonthContainer>
      )}
      {loading && <Spinner width="40px" height="40px" color="#3366CC" />}
      {isMobile && (
        <FloatContainer>
          <Btn
            variant="contained"
            color="primary"
            onClick={() => navigate("/reserva-tour")}
          >
            RESERVÁ NUEVO TOUR
          </Btn>
        </FloatContainer>
      )}
    </Container>
  )
}
