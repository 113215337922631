import { useState, useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../redux/hooks"
import { RootState } from "../../redux/store"
import { getAllTours, getAvailableDate } from "../../redux/reservas/toursSlice"
import {
  nextStep,
  setDateIdReserva,
  setDateReserva,
} from "../../redux/reservas/reservasSlice"
import { Spinner } from "../Spinner"
import { Title } from "./Title"
import { Box, useMediaQuery } from "@mui/material"
import { ContainerCalendar } from "./styles/styles"
import FullCalendar from "@fullcalendar/react"
import daygridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import esLocale from "@fullcalendar/core/locales/es"
import moment from "moment"
import "./styles/calendar.css"
import {
  setDateReservaEdit,
  setDateIdReservaEdit,
} from "../../redux/reservas/editReservaSlice"

export const Calendar = () => {
  const isMobile = useMediaQuery("(max-width:600px)")

  let date = new Date()

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  const dispatch = useAppDispatch()

  const { token } = useAppSelector((state: RootState) => state.auth)
  const { available, loading } = useAppSelector(
    (state: RootState) => state.tours
  )

  const { edit } = useAppSelector((state: any) => state.editTour)
  const [fullYearDates, setFullYearDates] = useState<any>([])
  const [initialDate, setInitialDate] = useState({
    start: moment(`${year}-${month}-${day}`).format("YYYY-MM-DD"),
    end: moment(`${year}-${month}-${day}`)
      .add(12, "month")
      .format("YYYY-MM-DD"),
  })

  const diasEntreFechas = function (hasta: any) {
    //Funcion para generar fechas todo el año
    let dia_actual = moment(`${year}/${month}/${day}`)
    let fechas = []
    while (dia_actual.isSameOrBefore(hasta)) {
      fechas.push({
        title: isMobile ? "" : "Sin Turno",
        start: dia_actual.format("YYYY-MM-DD"),
        end: dia_actual.format("YYYY-MM-DD"),
        color: "#9F9F9F",
        textColor: "#9F9F9F",
        extendedProps: {
          disponible: false,
        },
        display: "background",
      })
      dia_actual.add(1, "days")
    }
    return fechas
  }

  const getData = async () => {
    await dispatch(getAllTours())
    const parameters = {
      params: {
        dateFrom: initialDate.start,
        dateTo: initialDate.end,
      },
      token,
      allDays: fullYearDates,
    }
    await dispatch(getAvailableDate(parameters))
  }

  useEffect(() => {
    const allDays = diasEntreFechas(initialDate.end)
    setFullYearDates(allDays)
  }, [])

  useEffect(() => {
    if (fullYearDates.length > 0) {
      getData()
    }
  }, [fullYearDates])

  const handleSetDay = (day: string, date_id: number) => {
    if (edit) {
      dispatch(setDateReservaEdit(day))
      dispatch(setDateIdReservaEdit(date_id))
    } else {
      dispatch(setDateReserva(day))
      dispatch(setDateIdReserva(date_id))
    }
    dispatch(nextStep())
  }

  const Rango = {
    start: initialDate.start,
    end: initialDate.end,
  }

  return (
    <>
      <Title />
      {available.length > 0 && !loading ? (
        <ContainerCalendar>
          <FullCalendar
            headerToolbar={{
              center: "title",
              left: "prev,next",
              right: "today",
            }}
            dayHeaderFormat={{ weekday: "short" }}
            plugins={[daygridPlugin, interactionPlugin]}
            eventDidMount={(info: any) => {
              info.el.style.opacity = 1
              info.el.style.color = "#767676"
              if (
                info.event.extendedProps.disponible ||
                info.event.extendedProps.edit
              ) {
                info.el.style.color = "#FFFFFF"
              }
            }}
            eventClick={(info: any) => {
              if (
                info.event.extendedProps.disponible ||
                info.event.extendedProps.edit
              ) {
                handleSetDay(info.event.startStr, info.event.id)
              }
            }}
            initialView="dayGridMonth"
            weekends={false}
            eventSources={[
              {
                events: available,
              },
            ]}
            locale={esLocale}
            height="90%"
            validRange={Rango}
            showNonCurrentDates={true}
          />
        </ContainerCalendar>
      ) : (
        <Box
          sx={{
            width: "100vw",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner width="80px" height="80px" color="#3a89ca" />
        </Box>
      )}
    </>
  )
}
