import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { HeaderLayout } from "../layouts/HeaderLayout";
import { Container } from './styles/profilePageStyles';
import { Profile } from '../components/Profile';

import ReactGA from "react-ga4";

export const ProfilePage = () => {

    const { profile, loading } = useAppSelector((state: RootState) => state.profile);

    const isEmpty = profile.length <= 2 || loading;

    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Profile Page" });

    return (
        <Container isEmpty={isEmpty}>
            <HeaderLayout>
                <Profile />
            </HeaderLayout>
        </Container>
    )
};

