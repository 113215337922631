import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

export const ContainerCalendar = styled(Container)(({ theme }) => ({
  width: "95vw",
  height: "80vh",
  fontFamily: "Raleway",
  margin: "auto",
  [theme.breakpoints.up("md")]: {
    width: "70vw",
  },
}));
