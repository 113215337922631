import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import UseFetchTourProfile from '../../../hooks/UseFetchTourProfile';
import { NextBookings } from './NextBookings';
import { ScheduledBookings } from './ScheduledBookings';
import { Container, BookingsContainer, NotReserveText } from './styles';
import { Title } from './Title';
import { Spinner } from '../../Spinner';

export const Bookings = () => {

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: RootState) => state.auth);
  const { profile, loading } = useAppSelector((state: RootState) => state.profile);

    UseFetchTourProfile(user, dispatch);
  const isEmpty = profile.length <= 0 ? true : false;


  return (
    <Container>
      <Title />
      <BookingsContainer isEmpty={isEmpty} loading={loading}>
        {loading &&
          <Spinner
            width='100px'
            height='100px'
            color='#3366CC'
          />
        }
        {!loading && isEmpty &&
          <NotReserveText>No hay tours reservados</NotReserveText>
        }
        {!loading && !isEmpty &&
          <>
            <NextBookings/>
            <ScheduledBookings />
          </>
        }
      </BookingsContainer>
    </Container >
  )
}