import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";

export const Container = styled(Box)(({ theme }) => ({
  width: "95vw",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "15px",
  margin: "20px auto 50px auto",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "50px",
    width: "67vw",
  },
}));

export const Titulo = styled(Typography)(({ theme }) => ({
  fontFamily: "Telolet",
  fontWeight: 400,
  fontSize: "45px",
  lineHeight: "45px",
  color: "#3366CC",
}));

export const Subtitle = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "16.9733px",
  lineHeight: "20px",
  color: "#000000",
  flex: 1
}));

export const NextButton = styled(Button)(() => ({
  padding: "11px 22px",
  gap: "10px",
  background: "#3366CC",
  borderRadius: "86px"
}))
