import { useAppDispatch } from "../../redux/hooks";
import { FunctionComponent, Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { MenuHeader } from "./MenuHeader";

import { Nav, Links, Menu, LogosContainer, LinksContainer, ContainerLogoMobile } from "./styles";
import { Divider, useMediaQuery } from "@mui/material";

import LogoArcor from '../../assets/images/Header/LogoArcor.svg';
import FundacionArcorLogo from "../../assets/images/Header/FundacionLogo.svg";
import UniversoArcorLogo from "../../assets/images/Header/UniversoLogo.svg";
import { setEdit, setInitialState } from "../../redux/reservas/editReservaSlice";
import { setInitiState } from "../../redux/reservas/reservasSlice";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const Header: FunctionComponent<Props> = ({ setOpen }: Props) => {
  const { token } = useAppSelector((state: RootState) => state.auth);
  const matches = useMediaQuery("(min-width:1100px)");
  const dispatch = useAppDispatch();

  const handleResetState = () => {
    dispatch(setEdit());
    dispatch(setInitiState());
    // dispatch(setInitialState());
  }

  const handleExpClick = () => {
    const scroll = document.getElementById('videoInstitucional')
    scroll?.scrollIntoView()
    dispatch(setEdit());
    dispatch(setInitiState());

  }

  return (
    <Nav>
      {matches ? (
        <>
          <LogosContainer>
            <img
              src={LogoArcor}
              alt="Logo de Arcor"
              width="63.05"
              height="43.49"
            />
            <Divider flexItem orientation="vertical" sx={{ backgroundColor: '#3366CC', width: '1px' }} />
            <img
              src={FundacionArcorLogo}
              alt='Logo Fundacion Arcor'
              width="63px"
            />
            <Divider flexItem orientation="vertical" sx={{ backgroundColor: '#3366CC', width: '1px' }} />
            <img
              src={UniversoArcorLogo}
              alt='Universo Arcor Logo'
              width="66px"
            />
          </LogosContainer>
          <LinksContainer>
            <Links to="/" onClick={handleExpClick}>LA EXPERIENCIA</Links>
            <Links to="/quienes-somos" onClick={handleResetState}>QUIENES SOMOS</Links>
            <Links to={token ? "/reserva-tour" : "/auth/register"} onClick={handleResetState}>
            {/* <Links to={"/comingsoon"} onClick={handleResetState}> */}
              RESERVA TU VISITA
            </Links>
            <Links to="/contacto" onClick={handleResetState}>CONTACTO</Links>
            {token && <MenuHeader />}
          </LinksContainer>
        </>
      ) : (
        <>
          <Menu onClick={() => setOpen(true)} />
          <ContainerLogoMobile>
            <img
              src={LogoArcor}
              alt="Logo de Arcor"
              width="53.91px"
              height="37.18px"
            />
            <Divider flexItem orientation="vertical" sx={{ backgroundColor: '#3366CC', width: '1px' }} />
            <img
              src={FundacionArcorLogo}
              alt='Logo Fundacion Arcor'
              width="53px"              
            />
            <Divider flexItem orientation="vertical" sx={{ backgroundColor: '#3366CC', width: '1px' }} />
            <img
              src={UniversoArcorLogo}
              alt='Universo Arcor Logo'
              width="56px"
            />
          </ContainerLogoMobile>
        </>
      )}
    </Nav>
  );
};
