import { Typography, Box } from '@mui/material';
import { styled } from "@mui/material/styles";

type Props = {
  isOpen?: boolean;
  isMobile?: boolean;
}


export const MonthTitleText = styled(Typography)(() => ({
  fontFamily: "Raleway",
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "149.4%",
  color: "#000000",
  paddingLeft: '20px'
}));

export const MonthTitleContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 0px 30px 0px',
  borderTop: "3px solid #C4E6F4",
}))

export const Icon = styled('img')<Props>(({ isOpen, isMobile }) => ({
  display: isMobile ? 'block' : 'none',
  paddingRight: isOpen ? '20px' : '0px',
  paddingLeft: isOpen ? '0px' : '20px',
  transition: 'all .3s ease',
  transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)'
}));


export const CardContainer = styled(Box)<Props>(({ isMobile, theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: isMobile ? "39px" : '70px',
  marginBottom: '20px',
  [theme.breakpoints.up('md')]:{
    justifyContent: 'flex-start'
  }
}))