import { useState, useEffect } from "react"
import {
  MonthTitleContainer,
  MonthTitleText,
  Icon,
  CardContainer,
} from "./styles"
import { Card } from "../Card"
import { translate } from "../dictionary"
import { useMediaQuery } from "@mui/material"
import ArrowIcon from "../../../assets/images/misTours/Arrowicon.svg"

export const SwipeContainer = ({ element }: any) => {
  const isMobile = useMediaQuery("(max-width:900px)")
  const [isOpen, setIsOpen] = useState(true)
  const [orderElement, setOrderElement] = useState<any>([])

  useEffect(() => {
    const elements = element.bookings
    const dateOrderElements = [...elements].sort((a: any, b: any): any => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })
    setOrderElement(dateOrderElements)
  }, [])

  return (
    <>
      <MonthTitleContainer>
        <MonthTitleText>{translate(element.title)}</MonthTitleText>
        <Icon
          alt="Arrow icon"
          src={ArrowIcon}
          isOpen={isOpen}
          isMobile={isMobile}
          onClick={() => setIsOpen(!isOpen)}
        />
      </MonthTitleContainer>
      <CardContainer isMobile={isMobile}>
        {orderElement?.map((index: number, el: any) => (
          <Card key={index} element={el} isOpen={isOpen} />
        ))}
      </CardContainer>
    </>
  )
}
