import { useEffect, useState } from "react";
import {
  ContainerRecorridos,
  Container,
  ContainerSubHeader,
  FlexContainer,
  Icon,
  IconText,
  DataContainer,
  Title,
  Description,
  ButtonContainer,
  RouteText,
  ButtonBooking,
  FloatContainer,
} from "./ReservaRecorridoStyles";
import { useMediaQuery } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import {
  previousStep,
  setRecorrido,
  setStep,
  setSuccessFalse,
  setSelectRecorrido,
} from "../../../redux/reservas/reservasSlice";
import { Card } from "./Card";
import BackIcon from "../../../assets/images/profile/backIcon.svg";
import { sweetAlertMixin } from "../../../helpers/sweetAlert";

export const ReservaRecorrido = () => {
  const isMobile = useMediaQuery("(max-width:1100px)");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { success, loading } = useAppSelector(
    (state: RootState) => state.reservas
  );

  const { tours } = useAppSelector((state: RootState) => state.tours);

  const [routeSelected, setRouteSelected] = useState<any>(tours[0].paths);
  const [counterSelected, setCounterSelected] = useState<number>(0);
  const [pathsShow, setPathsShow] = useState<string[] | []>([]);

  const handleSetRecorrido = (recorrido: any) => {
    const updatedRoute = routeSelected.map((element: any) => {
      if (element.selected && element.id === recorrido.id) {
        return { ...element, selected: false };
      } else if (element.id === recorrido.id) {
        return { ...element, selected: true };
      } else {
        return { ...element, selected: false };
      }
    });

    setRouteSelected(updatedRoute);
  };

  useEffect(() => {
    // Function for disable send button if select user length < 2
    let counter = 0;
    let paths: number[] = [];
    let namePaths: string[] = [];
    routeSelected.map((element: any) => {
      if (element.selected === true) {
        counter++;
        paths.push(element.id);
        namePaths.push(element.title);
      }
      setCounterSelected(counter);
    });
    setPathsShow(namePaths);
    dispatch(setRecorrido(paths));
  }, [routeSelected, dispatch]);

  const handleBackStep = () => {
    dispatch(previousStep());
  };

  useEffect(() => {
    dispatch(setSelectRecorrido(routeSelected));
  }, [routeSelected]);

  useEffect(() => {
    if (success) {
      sweetAlertMixin("success", "Su reserva se guardo correctamente");
      navigate("/reserva/reserva-exitosa");
      dispatch(setSuccessFalse());
      dispatch(setStep(0));
    }
  }, [success]);

  return (
    <Container>
      <ContainerSubHeader>
        <FlexContainer onClick={handleBackStep}>
          <Icon alt="Back Icon" src={BackIcon} />
          <IconText>Volver</IconText>
        </FlexContainer>
        <DataContainer>
          <Title>Elegí el RECORRIDO</Title>
          {/* <Description>
            Viajá con nosotros a conocer nuestros procesos productivos. ¡Podes
            elegir dos recorridos!
          </Description> */}
          {!isMobile && (
            <ButtonContainer>
              <RouteText>
                {pathsShow.map((element, i) => {
                  if (i === 0 && pathsShow.length > 1) {
                    return element + " + ";
                  } else {
                    return element;
                  }
                })}
                {pathsShow.length < 1 && <>Seleccione</>}
              </RouteText>
              <ButtonBooking
                variant="contained"
                disabled={loading || counterSelected <= 0}
                onClick={() => navigate("/confirm-reserve")}
              >
                Continuar
              </ButtonBooking>
            </ButtonContainer>
          )}
        </DataContainer>
      </ContainerSubHeader>
      <ContainerRecorridos>
        {routeSelected.map((element: any) => (
          <Card
            key={element.title}
            element={element}
            handleSetRecorrido={handleSetRecorrido}
          />
        ))}
      </ContainerRecorridos>
      {isMobile && (
        <>
          <div style={{ marginTop: "100px" }}></div>
          <FloatContainer>
            <ButtonContainer isMobile={isMobile}>
              <RouteText>
                {pathsShow.map((element, i) => {
                  if (i === 0 && pathsShow.length > 1) {
                    return element + " + ";
                  } else {
                    return element;
                  }
                })}
                {pathsShow.length < 1 && <>Seleccione</>}
              </RouteText>
              <ButtonBooking
                variant="contained"
                disabled={loading || counterSelected <= 0}
                onClick={() => navigate("/confirm-reserve")}
              >
                Continuar
              </ButtonBooking>
            </ButtonContainer>
          </FloatContainer>
        </>
      )}
    </Container>
  );
};
