import { Title } from './Title';
import { Information } from './Information';
import { Bookings } from './Bookings';
import { BookingButton } from './Bookings/BookingButton';
import { Container, FloatContainer } from './styles';

import { Box, useMediaQuery } from '@mui/material';

export const Profile = () => {

    const isMobile = useMediaQuery("(max-width:1200px)");
    const isMobileSmall = useMediaQuery("(max-width:900px)");

    return (
        <Container>
            <Box>
                <Title />
                {!isMobile && <Information />}
            </Box>
            {isMobileSmall &&
                <FloatContainer>
                    <BookingButton />
                </FloatContainer>}
            <Bookings />
            {isMobile && <Information />}
        </Container>
    )
}